<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" title_name="在线登记详情"></nav-bar-component>
        <div>
            <van-cell-group inset >
                <van-cell title="状态">
                    <template #right-icon>
                        <van-tag plain color="#ADADAD" v-if="details.state===0">待分配</van-tag>
                        <van-tag plain type="primary" v-else-if="details.state===1">已分配</van-tag>
                        <van-tag plain type="warning" v-else-if="details.state===2">装卸中</van-tag>
                        <van-tag plain type="success" v-else-if="details.state===3">已完成</van-tag>
                        <van-tag plain type="danger" v-else-if="details.state===4">已取消</van-tag>
                        <van-tag plain type="danger" v-else-if="details.state===5">已过期</van-tag>
                    </template>
                </van-cell>
                <van-cell title="业务类型" :value="details.car_business_type" />
                <van-cell v-if="details.box_num" title="集装箱号" :value="details.box_num" />
                <van-cell title="车辆类型" :value="details.car_size_type" />
                <van-cell title="车牌号" :value="details.car_number" />
                <van-cell title="集装箱号" :value="details.box_num" />
                <van-cell title="司机姓名" :value="details.car_driver_name" />
                <van-cell title="联系电话" :value="details.car_driver_phone" />
                <van-cell title="身份证号" :value="details.car_driver_icard" />
                <van-cell title="装卸平台" :value="details.with_terrace_name" />
                <van-cell title="排队号" :value="details.list_num" />
                <van-cell title="装卸开始时间" :value="details.start_time" />
                <van-cell title="装卸结束时间" :value="details.end_time" />
                <van-cell title="身份证号" :value="details.car_driver_icard" />
                <van-cell title="身份证正面">
                    <template #label>
                        <uploader-component
                            :farr="details.icard_file_a"
                            :show_upload="false"
                            :deletable="false"
                        ></uploader-component>
                    </template>
                </van-cell>
                <van-cell title="身份证反面">
                    <template #label>
                        <uploader-component
                            :farr="details.icard_file_b"
                            :show_upload="false"
                            :deletable="false"
                        ></uploader-component>
                    </template>
                </van-cell>
                <van-cell title="驾驶证正面">
                    <template #label>
                        <uploader-component
                            :farr="details.jscard_file_a"
                            :show_upload="false"
                            :deletable="false"
                        ></uploader-component>
                    </template>
                </van-cell>
                <van-cell title="驾驶证反面">
                    <template #label>
                        <uploader-component
                            :farr="details.jscard_file_a"
                            :show_upload="false"
                            :deletable="false"
                        ></uploader-component>
                    </template>
                </van-cell>
                <van-cell title="行驶证正面">
                    <template #label>
                        <uploader-component
                            :farr="details.xscard_file_a"
                            :show_upload="false"
                            :deletable="false"
                        ></uploader-component>
                    </template>
                </van-cell>
                <van-cell title="行驶证反面">
                    <template #label>
                        <uploader-component
                            :farr="details.xscard_file_b"
                            :show_upload="false"
                            :deletable="false"
                        ></uploader-component>
                    </template>
                </van-cell>
            </van-cell-group>
            <div class="sub_button">
                <van-button round block type="danger" @click="on_cancel" v-if="((details.state === 0) || (details.state === 1))">取消登记</van-button>
            </div>
            <div class="sub_button">
                <van-button round block type="default" @click="go_back">返 回</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'
import UploaderComponent from '@/components/common/UploaderComponent.vue'

import { car_driver_appoint_details_request,car_driver_appoint_cancel_request } from '@/network/CarDriverAppoint'

export default {
    name:'CarDriverCarDetailsComponent',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            id: 0,
            details: {
                state: '',
                car_business_type: '',
                box_num: '',
                car_size_type: '',
                car_number:'',
                car_driver_name: '',
                car_driver_phone: '',
                car_driver_icard: '',
                with_terrace_name: '',
                list_num: '',
                start_time: '',
                end_time: '',
                icard_file_a: [],
                icard_file_b: [],
                jscard_file_a: [],
                jscard_file_b: [],
                xscard_file_a: [],
                xscard_file_b: []
            },
            edit: false,
            all_car_size_type: []
        }
    },
    computed:{},
    methods:{
        get_car_driver_appoint_details() {
            this.$store.commit('true_loading')
            car_driver_appoint_details_request(this.id)
                .then((s) => {
                    if (s.status === 0) {
                        this.details = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        go_back() {
            this.$router.go(-1);//返回上一层
        },
        on_cancel() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定取消登记？（取消后，只能重新再进行登记）',
            })
            .then(() => {
                this.$store.commit('true_loading')
                car_driver_appoint_cancel_request({id: this.id})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('已取消!')
                                this.$router.go(-1);//返回上一层
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        }
    },
    filters:{},
    props:{},
    created(){
        this.id = this.$route.query.id
        this.get_car_driver_appoint_details()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent,
        UploaderComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
</style>